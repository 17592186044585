import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'


import img4 from "../../images/reproad/4.jpg"
import img5 from "../../images/reproad/5.jpg"


import reproad_intro from "../../images/reproad/reproad intro.mp4"
import reproad_machine from "../../images/reproad/reproad machine.mp4"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;





export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    REPROAD is the largest road milling company in Switzerland and probably also operates the biggest machines.
                    <p/>
                    <a href="http://www.tbsagency.com" target="_blank">TBS</a> managed the project, <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> did a complete redesign of their website, and <LinkABOUT location={location}>I</LinkABOUT> wrote the text.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>



              </Type>
        </English>



        <Deutsch Language={location.search}>
              <Type>
                    REPROAD ist die grösste Fräsfirma in der Schweiz und fährt die auch die grössten Maschinen.
                    <p/>
                    <a href="http://www.tbsagency.com" target="_blank">TBS</a> leitete das Projekt, <a href="http://www.kilokilo.ch" target="_blank">KiloKilo</a> gestaltete die Website neu, und <LinkABOUT location={location}>Ich</LinkABOUT> schrieb den Text.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </Deutsch>


    </div>

<Element name="test1"></Element>


<video  autoPlay loop muted>
<source src={reproad_intro} type="video/mp4" />
Your browser does not support the video tag.
</video>





      <div className="comment">
        <English Language={location.search}>
              <Type>
              The website became like the REPROAD machines and people: strong and bold.
              </Type>
        </English>



        <Deutsch Language={location.search}>
              <Type>

                    Die Webseite wurde wie die Maschinen und Mitarbeiter von REPROAD: stark und gewaltig.

              </Type>
        </Deutsch>

      </div>



      <video  autoPlay loop muted>
<source src={reproad_machine} type="video/mp4" />
Your browser does not support the video tag.
</video>







      <div className="comment">
        <English Language={location.search}>
              <Type>

              The verbal identity works with a monstrous sentence structure and word choices, giving this armada of machines the space it needs.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    
                    Die verbale Identität arbeitet mit monströsen Satzstrukturen und entsprechender Wortwahl, damit die Armada von Maschinen den benötigten Raum bekommt.

              </Type>
        </Deutsch>

      </div>

      <img src={img4}/>


      <div className="comment">
        <English Language={location.search}>
              <Type>

              An in-depht technical understanding of how the company and their machines work is now milled into my brain.

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    Ein tiefgehendes technisches Verständnis wie die Firma und ihre Maschinen arbeitet ist nun in mein Gehirn gefräst.

              </Type>
        </Deutsch>

      </div>


      <img src={img5}/>






      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
